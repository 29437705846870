import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Dashboard from "../Pages/Dashboard";
import Profile from "../Pages/Profile";
import Verification from "../Pages/Verification";
import Users from "../Pages/Users";
import GeneralList from "../Pages/GeneralList";
import Spot from "../Pages/Spot";
import Deposit from "../Pages/Deposit";
import DepositAccounts from "../Pages/DepositAccounts";
import DepositWithdrawTransferHistory from "../Pages/DepositWithdrawTransferHistory";
import SpotFutureHistoryDetails from "../Pages/SpotFutureHistoryDetails";
import SpotFutureHistory from "../Pages/SpotFutureHistory";
import FutureProcessHistory from "../Pages/FutureProcessHistory";
import ActiveFutureProcesses from "../Pages/ActiveFutureProcesses";
import Withdraw from "../Pages/Withdraw";
import WithdrawRequests from "../Pages/WithdrawRequests";
import SupportTickets from "../Pages/SupportTickets";
import Login from "../Pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import "@fontsource/manrope";
import "../global.css";
import GlobalTop from "../Pages/Section-GlobalTop";
import { Helmet } from 'react-helmet';
import CreditFeaturesChange from "../Pages/CreditFeaturesChange";

const MainRouter = () => {

  const globalTitle = "KPRICE";

  function RouteLayout({ headTitle, pageTitle, description }) {
    return (
      <>
        <Helmet>
          <title>{headTitle != "" ? `${headTitle} - ${globalTitle}` : globalTitle}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Sidebar />
        <GlobalTop title={pageTitle} />
      </>
    );
  }

  return (
    <Routes>
      <Route path="/" element={
        <ProtectedRoute>
          <RouteLayout headTitle="" pageTitle="Dashboard" description="" />
          <Dashboard />
        </ProtectedRoute>
      } />
      <Route path="/deposit-accounts" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Deposit Accounts" pageTitle="Deposit Accounts" description="" />
          <DepositAccounts />
        </ProtectedRoute>
      } />
      <Route path="/profile" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Profile" pageTitle="Profile" description="" />
          <Profile pageType="profile" />
        </ProtectedRoute>
      } />
      <Route path="/profile/future" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Future" pageTitle="Future" description="" />
          <Profile pageType="future" />
        </ProtectedRoute>
      } />
      <Route path="/verification/*" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Verification" pageTitle="Verification" description="" />
          <Verification />
        </ProtectedRoute>
      } />
      <Route path="/users" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Users" pageTitle="Users" description="" />
          <Users />
        </ProtectedRoute>
      } />
      <Route path="/general-list" element={
        <ProtectedRoute>
          <RouteLayout headTitle="General List" pageTitle="General List" description="" />
          <GeneralList />
        </ProtectedRoute>
      } />
      <Route path="/deposit/*" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Deposit" pageTitle="Deposit" description="" />
          <Deposit />
        </ProtectedRoute>
      } />
      <Route path="/credit-features-change/*" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Credit Features" pageTitle="Credit Features" description="" />
          <CreditFeaturesChange />
        </ProtectedRoute>
      } />
      <Route path="/last-deposits" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Last Deposits" pageTitle="Last Deposits" description="" />
          <DepositWithdrawTransferHistory dataType="deposit" listType="scroll" />
        </ProtectedRoute>
      } />
      <Route path="/deposits" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Deposits" pageTitle="Deposits" description="" />
          <DepositWithdrawTransferHistory dataType="deposit" listType="date" />
        </ProtectedRoute>
      } />
      <Route path="/withdraw/*" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Withdraw" pageTitle="Withdraw" description="" />
          <Withdraw />
        </ProtectedRoute>
      } />
      <Route path="/withdraw-requests" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Withdraw Requests" pageTitle="Withdraw Requests" description="" />
          <WithdrawRequests />
        </ProtectedRoute>
      } />
      <Route path="/last-withdraws" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Last Withdraws" pageTitle="Last Withdraws" description="" />
          <DepositWithdrawTransferHistory dataType="withdraw" listType="scroll" />
        </ProtectedRoute>
      } />
      <Route path="/withdraws" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Withdraws" pageTitle="Withdraws" description="" />
          <DepositWithdrawTransferHistory dataType="withdraw" listType="date" />
        </ProtectedRoute>
      } />
      <Route path="/last-transfers" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Last Transfers" pageTitle="Last Transfers" description="" />
          <DepositWithdrawTransferHistory dataType="transfer" listType="scroll" />
        </ProtectedRoute>
      } />
      <Route path="/transfers" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Transfers" pageTitle="Transfers" description="" />
          <DepositWithdrawTransferHistory dataType="transfer" listType="date" />
        </ProtectedRoute>
      } />
      <Route path="/spot" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Spot" pageTitle="Spot" description="" />
          <Spot />
        </ProtectedRoute>
      } />
      <Route path="/customer/spotorder" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Spot Orders Details" pageTitle="Spot Orders Details" description="" />
          <SpotFutureHistoryDetails dataType="spotorder" pageType="customer" />
        </ProtectedRoute>
      } />
      <Route path="/customer/futureorder" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Future Orders Details" pageTitle="Future Orders Details" description="" />
          <SpotFutureHistoryDetails dataType="futureorder" pageType="customer" />
        </ProtectedRoute>
      } />
      <Route path="/customer/futurepositionhistory" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Future Position History Details" pageTitle="Future Position History Details" description="" />
          <SpotFutureHistoryDetails dataType="futurepositionhistory" pageType="customer" />
        </ProtectedRoute>
      } />
      <Route path="/last-spot-orders" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Last Spot Orders" pageTitle="Last Spot Orders" description="" />
          <SpotFutureHistory fromPage="history" dataType="spot" dataCurrency={null} dataCustomerId={null} />
        </ProtectedRoute>
      } />
      <Route path="/spot-orders" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Spot Orders" pageTitle="Spot Orders" description="" />
          <SpotFutureHistoryDetails dataType="spotorder" pageType="all" />
        </ProtectedRoute>
      } />
      <Route path="/last-future-orders" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Last Future Orders" pageTitle="Last Future Orders" description="" />
          <SpotFutureHistory fromPage="history" dataType="future" dataCurrency={null} dataCustomerId={null} />
        </ProtectedRoute>
      } />
      <Route path="/future-orders" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Future Orders" pageTitle="Future Orders" description="" />
          <SpotFutureHistoryDetails dataType="futureorder" pageType="all" />
        </ProtectedRoute>
      } />
      <Route path="/last-future-positions" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Last Future Positions" pageTitle="Last Future Positions" description="" />
          <FutureProcessHistory fromPage="history" />
        </ProtectedRoute>
      } />
      <Route path="/future-positions" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Future Positions" pageTitle="Future Positions" description="" />
          <SpotFutureHistoryDetails dataType="futurepositionhistory" pageType="all" />
        </ProtectedRoute>
      } />
      <Route path="/active-future-positions" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Active Future Positions" pageTitle="Active Future Positions" description="" />
          <ActiveFutureProcesses />
        </ProtectedRoute>
      } />
      <Route path="/tickets" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Tickets" pageTitle="Tickets" description="" />
          <SupportTickets />
        </ProtectedRoute>
      } />
      <Route path="/login" element={
        <>
          <Helmet>
            <title>{globalTitle}</title>
            <meta name="description" content="" />
          </Helmet>
          <Login pageType="login" />
        </>
      } />
    </Routes>
  );

};

export default MainRouter;