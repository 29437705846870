import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../components/Global/Loading";
import moment from "moment-timezone";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import SupportChat from "./SupportChat";
import "./Styles/page_tickets.css";
import "./Styles/page_dashboard.css";
import { onSupportActiveOpen, onSupportDeactiveOpen, onSupportDeactiveClose, onGetSupportActiveTicketsMore, onGetSupportDeactiveTicketsMore } from "../Reducer/kpriceReducer";

const SupportTickets = () => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const dispatch = useDispatch();

    const ticketArea = useRef(null);
    const chatArea = useRef(null);

    const [tabValue, setTabValue] = useState("ACTIVE");
    const [tabData, setTabData] = useState(null);

    const supportActiveTickets = useSelector((state) => state.kprice.supportActiveTickets.data);
    const supportDeactiveTickets = useSelector((state) => state.kprice.supportDeactiveTickets.data);
    const [activeTicketsLoaded, setActiveTicketsLoaded] = useState(false);
    const [deactiveTicketsLoaded, setDeactiveTicketsLoaded] = useState(false);

    const [selectedTicketForChat, setSelectedTicketForChat] = useState(null);
    const [selectedTicketForChatState, setSelectedTicketForChatState] = useState(null);
    const [selectedTicketForChatStatus, setSelectedTicketForChatStatus] = useState(null);
    const [selectedTicketForChatOperationStatus, setSelectedTicketForChatOperationStatus] = useState(null);

    const [closeTicketLoadingList, setCloseTicketLoadingList] = useState([]);

    const [loadingMore, setLoadingMore] = useState(false);
    const itemsScrollPosition = useRef(0);

    const closeTicket = async (item) => {
        if(item.log_id && item.state == "closed" && item.status == "deactive" && item.operation_status != "deactive"){
            setCloseTicketLoadingList((prevItems) => [...prevItems, item.log_id]);

            let data = {
                "ticket_log_id": item.log_id,
                "state": "close_for_support",
            }
            
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://operationapi.kpriceapp.com/support/updatestate', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    setCloseTicketLoadingList((prevItems) => prevItems.filter((item) => item !== item.log_id));
                }
                else{//hatalı durum için
                    toast.error(`There was a problem closing the ticket!${response.data.message ? ` (${response.data.message})` : ""}`);
                    setCloseTicketLoadingList((prevItems) => prevItems.filter((item) => item !== item.log_id));
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
                setCloseTicketLoadingList((prevItems) => prevItems.filter((item) => item !== item.log_id));
            });
        }
    }

    useEffect(() => {
        if(ticketArea && ticketArea.current){
            if(loadingMore){
                ticketArea.current.scrollTop = ticketArea.current.scrollHeight - ticketArea.current.clientHeight;
            }
            else{
                ticketArea.current.scrollTop = itemsScrollPosition.current;
            }
        }
    }, [loadingMore]);

    const elementsOnScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;

        let ticketsData = tabValue == "ACTIVE" ? supportActiveTickets : supportDeactiveTickets;

        if (scrollTop + clientHeight + 5 >= scrollHeight && ticketsData && ticketsData.last_evaluated_key && !loadingMore) {//eğer divin en altına gelindiyse
            if(ticketArea && ticketArea.current){
                itemsScrollPosition.current = ticketArea.current.scrollTop;
            }

            setLoadingMore(true);

            if(tabValue == "ACTIVE"){
                dispatch(onGetSupportActiveTicketsMore());
            }
            else{
                dispatch(onGetSupportDeactiveTicketsMore());
            }
        }
    }
    
    const ticketStateText = (state) => {
        if(state == "open"){return "Open";}
        else if(state == "closed"){return "Closed";}
        else if(state == "close_for_support"){return "Close for Support";}
        else if(state == "reopened"){return "Reopened";}
        else if(state == "waiting_for_customer"){return "Waiting for Customer";}
        else if(state == "in_progress"){return "In Progress";}
        else if(state == "resolved"){return "Resolved";}
        else{return null;}
    }

    const ticketStateColor = (state) => {
        if(state == "open"){return "success1";}
        else if(state == "reopened"){return "success2";}
        else if(state == "in_progress"){return "waiting2";}
        else if(state == "waiting_for_customer"){return "waiting1";}
        else if(state == "closed" || state == "close_for_support"){return "danger1";}
        else if(state == "resolved"){return "danger2";}
        else{return "";}
    }

    const ticketDateText = (timestamp) => {
        let eventDateText = timestamp;
        
        const now = moment().tz(timeZone);
        let eventDate = moment(eventDateText).tz(timeZone);

        if(eventDate.isSame(now, 'day')){
            eventDateText = eventDate.format("HH:mm");
        }
        else if(eventDate.isSame(now.clone().subtract(1, 'day'), 'day')){
            eventDateText = "Yesterday";
        }
        else if(eventDate.isAfter(now.clone().subtract(7, 'days'), 'day')){
            eventDateText = eventDate.format("dddd");
        }
        else{
            eventDateText = eventDate.format("DD.MM.YYYY");
        }

        return eventDateText;
    }

    useEffect(() => {
        if(tabValue == "ACTIVE"){
            setTabData(supportActiveTickets && supportActiveTickets.support_requests ? supportActiveTickets.support_requests : null);
        }
        else if(tabValue == "DEACTIVE"){
            setTabData(supportDeactiveTickets && supportDeactiveTickets.support_requests ? supportDeactiveTickets.support_requests : null);
        }
    }, [tabValue, supportActiveTickets, supportDeactiveTickets]);

    useEffect(() => {
        setLoadingMore(false);
        
        if(selectedTicketForChat && selectedTicketForChat.log_id && selectedTicketForChat.customer_id){
            let newChat = null;

            if(supportActiveTickets && supportActiveTickets.support_requests){
                const existingIndex = supportActiveTickets.support_requests.findIndex(
                    item => item.log_id === selectedTicketForChat.log_id && item.customer_id === selectedTicketForChat.customer_id
                );

                if (existingIndex != -1) {
                    newChat = supportActiveTickets.support_requests[existingIndex];
                }
            }

            if(supportDeactiveTickets && supportDeactiveTickets.support_requests){
                const existingIndex = supportDeactiveTickets.support_requests.findIndex(
                    item => item.log_id === selectedTicketForChat.log_id && item.customer_id === selectedTicketForChat.customer_id
                );

                if (existingIndex != -1) {
                    newChat = supportDeactiveTickets.support_requests[existingIndex];
                }
            }

            if(newChat){
                setSelectedTicketForChatState(newChat.state);
                setSelectedTicketForChatStatus(newChat.status);
                setSelectedTicketForChatOperationStatus(newChat.operation_status);
            }
        }
    }, [supportActiveTickets, supportDeactiveTickets]);

    useEffect(() => {
        if(!activeTicketsLoaded){
            dispatch(onSupportActiveOpen());
            setActiveTicketsLoaded(true);
        }

        if(!deactiveTicketsLoaded){
            dispatch(onSupportDeactiveOpen());
            setDeactiveTicketsLoaded(true);
        }

        ticketArea.current.style.animation = "none";
        let tempForAnimation1 = ticketArea.current.offsetHeight;
        ticketArea.current.style.animation = "open-animation 1.5s";
    }, [tabValue]);
    
    useEffect(() => {
        if(selectedTicketForChat){
            setSelectedTicketForChatState(selectedTicketForChat.state);
            setSelectedTicketForChatStatus(selectedTicketForChat.status);
            setSelectedTicketForChatOperationStatus(selectedTicketForChat.operation_status);
            
            chatArea.current.style.animation = "none";
            let tempForAnimation2 = chatArea.current.offsetHeight;
            chatArea.current.style.animation = "open-animation 1.5s";
        }
    }, [selectedTicketForChat]);

    useEffect(() => {
        return () => {
            dispatch(onSupportDeactiveClose());
        };
    }, []);
    
    return(
        <div id="main" className="main">
            <div className="section-tickets">
                <div className="allsections allsections-border tickets-area table-area">
                    <div className="tab-area">
                        <label className={tabValue == "ACTIVE" ? "active" : undefined} onClick={() => {setTabValue("ACTIVE");}}>ACTIVE</label>
                        <label className={tabValue == "DEACTIVE" ? "active" : undefined} onClick={() => {setTabValue("DEACTIVE");}}>DEACTIVE</label>
                    </div>
                    <div className="tickets" ref={ticketArea} onScroll={(e) => elementsOnScroll(e)}>
                        {tabData == null ? 
                            <div className="loading">
                                <Loading />
                            </div>
                            : 
                            <>
                                {tabData.length > 0 ? 
                                    <>
                                        {tabData.map((item) => (
                                            ((tabValue == "ACTIVE" && item.operation_status == "active") || (tabValue == "DEACTIVE" && item.operation_status == "deactive")) && 
                                                <div key={item.log_id} className={`item ${selectedTicketForChat && selectedTicketForChat.log_id == item.log_id && "active"}`} onClick={() => setSelectedTicketForChat(item)}>
                                                    <div className="signal">
                                                        {item.last_event_user_type == "Customer" && item.operation_status == "active" && <i className="fa-solid fa-exclamation"></i>}
                                                    </div>
                                                    <div className="status">
                                                        <div className={`status-info ${ticketStateColor(item.state)}`}>{ticketStateText(item.state)}</div>
                                                        <div className="ticket-info"><div className="ticket-info1">Ticket ID:</div><div className="ticket-info2">{item.ticket_id}</div></div>
                                                    </div>
                                                    <Tooltip title="Profile" className="detail-button" placement="top" onClick={(event) => event.stopPropagation()}>
                                                        <a href={`/profile?customer_id=${item.customer_id}`} target="_blank" className="profile-button">
                                                            <i className="fa-solid fa-user"></i>
                                                            <span>{item.customer_id}</span>
                                                        </a>
                                                    </Tooltip>
                                                    <div className="content">
                                                        <div className="subject-area">
                                                            <div className="subject">{item.subject}</div>
                                                            <div className="date">{ticketDateText(item.last_event_timestamp)}</div>
                                                        </div>
                                                        <div className={`message-area ${item.state == "closed" && item.status == "deactive" && item.operation_status != "deactive" ? "with-close-button" : ""}`} >
                                                            {item.last_event_type && item.last_event_type == "Message" ? 
                                                                <>
                                                                    <div className="message-user">{item.last_event_user_type}:</div>
                                                                    <div className="message">{item.last_event_content}</div>
                                                                </>
                                                                : 
                                                                (item.last_event_type && item.last_event_type == "Photo" ? 
                                                                    <>
                                                                        <div className="message-user">{item.last_event_user_type}:</div>
                                                                        <i className="fa-solid fa-camera"></i>
                                                                        <div className="message">Photo</div>
                                                                    </>
                                                                    : 
                                                                    (item.last_event_type && item.last_event_type == "File" ? 
                                                                        <>
                                                                            <div className="message-user">{item.last_event_user_type}:</div>
                                                                            <i className="fa-solid fa-file-lines"></i>
                                                                            <div className="message">File</div>
                                                                        </>
                                                                        : 
                                                                        null
                                                                    )
                                                                )
                                                            }
                                                            {item.state == "closed" && item.status == "deactive" && item.operation_status != "deactive" && 
                                                                (closeTicketLoadingList.includes(item.log_id) ? 
                                                                    <div className="close-button-loading"><Loading /></div>
                                                                    : 
                                                                    <Tooltip title={ticketStateText("close_for_support")} className="close-button" onClick={(event) => {event.stopPropagation();closeTicket(item);}}>
                                                                        <IconButton>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        ))}
                                        {loadingMore && <div className="loading-more"><Loading /></div>}
                                    </>
                                    : 
                                    <div className="no-tickets">
                                        <i className="fa-solid fa-comment-slash"></i>
                                        <span>No ticket</span>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
                <div className="allsections allsections-border chat-area">
                    <div className="chat" ref={chatArea} key={selectedTicketForChat && selectedTicketForChat.log_id ? selectedTicketForChat.log_id : undefined}>
                        {selectedTicketForChat ? 
                            <SupportChat ticket={selectedTicketForChat} ticketStateText={ticketStateText} ticketStateColor={ticketStateColor} ticketState={selectedTicketForChatState} ticketStatus={selectedTicketForChatStatus} ticketOperationStatus={selectedTicketForChatOperationStatus} />
                            : 
                            <div className="no-chat">
                                <i className="fa-solid fa-comment-dots"></i>
                                <span>Select a ticket to take action</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SupportTickets;