import axios from "axios";
import { Auth } from 'aws-amplify';

function adjustData_products(data) {
  const columnsToAdjust = ['show_places', '24h_c', '24h_h', '24h_l', '24h_o', '24h_q', '24h_v', 'max_before_places', 'max_order_asking_price_places', 'max_order_count_places', 'min_order_count', 'min_order_precision', 'price', 'value'];
  const adjustedData = { ...data };

  //products içerisindeki objeleri dönüştür
  adjustedData.products = adjustedData.products.map(product => {
    let newProduct = { ...product };
    columnsToAdjust.forEach(col => {
      if (col in newProduct) {
        newProduct[col] = Number(newProduct[col]);
      }
    });

    // future_leverages dizilerini dönüştür
    if (newProduct.future_leverages) {
      newProduct.future_leverages = newProduct.future_leverages.map(Number);
    }

    return newProduct;
  });

  //currencies içerisindeki objeleri dönüştür
  adjustedData.currencies = adjustedData.currencies.map(currency => {
    let newCurrency = { ...currency };
    if ('show_places' in newCurrency) {
      newCurrency['show_places'] = Number(newCurrency['show_places']);
    }
    return newCurrency;
  });

  // variables içerisindeki objeleri dönüştür
  adjustedData.variables = adjustedData.variables.map(variable => {
    let newVariable = { ...variable };
    if (newVariable.name === 'spot_commission_rate' || newVariable.name === 'future_commission_rate' || newVariable.name === 'currency_max_before_places') {
      newVariable['value'] = Number(newVariable['value']);
    }
    return newVariable;
  });

  return adjustedData;
}

function adjustData_users(data) {
  // Dönüştürülecek alanlar
  const columnsToAdjust = ['registration_time', 'status_level'];

  // Her bir kullanıcı objesi için dönüşüm gerçekleştir
  data.customers.forEach(user => {
    columnsToAdjust.forEach(col => {
      // Eğer kullanıcı objesinde bu alan varsa, sayıya çevir
      if (user[col]) {
        user[col] = Number(user[col]);
      }
    });
  });

  // Güncellenmiş veriyi döndür
  return data;
}

function adjustData_deposit(data) {
  if(data.type != "types_counts"){
    const columnsToAdjust = ['adding_timestamp', 'assignment_time'];
    data.deposit_accounts.forEach(item => {
      columnsToAdjust.forEach(col => {
        if (item[col]) {
          item[col] = Number(item[col]);
        }
      });
    });
  }
  
  return data;
}

function adjustData_spot(data) {
  if(data.type == "all"){
    const columnsToAdjust = ['total_count'];
    data.wallet_items.forEach(item => {
      columnsToAdjust.forEach(col => {
        if (item[col]) {
          item[col] = Number(item[col]);
        }
      });
    });
  }
  
  return data;
}

function adjustData_futureProfiles(data) {
  const columnsToAdjust = ['balance', 'total_surety', 'credit', 'credit_deposit'];

  const adjustedProfileItems = data.profile_items.map(item => {
    let adjustedItem = { ...item };
    columnsToAdjust.forEach(col => {
      if (col in adjustedItem) {
        adjustedItem[col] = Number(adjustedItem[col]);
      }
    });
    return adjustedItem;
  });

  return { ...data, profile_items: adjustedProfileItems };
}

function adjustData_futureProcesses(data) {
  const columnsToAdjust = ['available_count', 'available_surety', 'entry_count', 'entry_price', 'entry_surety', 'entry_timestamp', 'leverage', 'result_timestamp', 'process_profit'];

  const adjustedProcessItems = data.process_items.map(item => {
    let adjustedItem = { ...item };
    columnsToAdjust.forEach(col => {
      if (col in adjustedItem) {
        adjustedItem[col] = Number(adjustedItem[col]);
      }
    });
    return adjustedItem;
  });

  return { ...data, process_items: adjustedProcessItems };
}

function adjustData_supportTickets(data) {
  let adjustedData = { ...data };
  const columnsToAdjust = ['event_timestamp', 'last_event_timestamp', 'last_updated'];

  if (adjustedData.support_requests) {
    adjustedData.support_requests = adjustedData.support_requests.map(item => {
      let newItem = { ...item };
      columnsToAdjust.forEach(col => {
        if (col in newItem) {
          newItem[col] = Number(newItem[col]);
        }
      });
      return newItem;
    });
  }

  return adjustedData;
}

function adjustData_supportChat(data) {
  let adjustedData = { ...data };
  const columnsToAdjust = ['event_timestamp'];

  if (adjustedData.support_request_events) {
    adjustedData.support_request_events = adjustedData.support_request_events.map(event => {
      let newEvent = { ...event };
      columnsToAdjust.forEach(col => {
        if (col in newEvent) {
          newEvent[col] = Number(newEvent[col]);
        }
      });
      return newEvent;
    });
  }

  return adjustedData;
}

export const kpriceApi = {
  //products
  getProductIds: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/products`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_products(res.data);
      return {
        data: resData
      };
    });
  },


  //users
  getUsers: async ({ type }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/users?type=${type}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_users(res.data);
      return {
        data: resData
      };
    });
  },


  //deposit
  getDeposit: async ({ type }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/deposit/account?type=${type}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_deposit(res.data);
      return {
        data: resData
      };
    });
  },


  //spot
  getSpot: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/wallet?type=all`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_spot(res.data);
      return {
        data: resData
      };
    });
  },


  //withdraw requests
  getWithdrawRequests: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/withdraw/request?type=all`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = res.data;
      return {
        data: resData
      };
    });
  },


  //future profile
  getFutureProfiles: async ({ customerId }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/futureprofile?${customerId ? `type=user&customer_id=${customerId}` : `type=all`}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureProfiles(res.data);
      return {
        data: resData
      };
    });
  },


  //future process
  getFutureProcesses: async ({ customerId }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/futureprocess/active/?${customerId ? `type=user&customer_id=${customerId}` : `type=all`}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureProcesses(res.data);
      return {
        data: resData
      };
    });
  },


  //support tickets
  getSupportActiveTickets: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/support?request_type=active`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_supportTickets(res.data);
      return {
        data: resData
      };
    });
  },
  getSupportActiveTicketsMore: async ({ log_id, last_updated }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/support?log_id=${log_id}&last_updated=${last_updated}&request_type=active`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_supportTickets(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },
  getSupportDeactiveTickets: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/support?request_type=deactive`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_supportTickets(res.data);
      return {
        data: resData
      };
    });
  },
  getSupportDeactiveTicketsMore: async ({ log_id, last_updated }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/support?log_id=${log_id}&last_updated=${last_updated}&request_type=deactive`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_supportTickets(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },

  
  //support chat
  getSupportChat: async ({ log_id }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/support/events?log_id=${log_id}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_supportChat(res.data);
      return {
        data: resData
      };
    });
  },
  getSupportChatMore: async ({ log_id, event_id, event_timestamp }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://operationapi.kpriceapp.com/support/events?log_id=${log_id}&event_id=${event_id}&event_timestamp=${event_timestamp}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_supportChat(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },
};
