import { getDatabase, ref, onValue, off, enableLogging } from "firebase/database";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDbyPekGeX9KwadbUlRQYjFIUJSTtnMHFw",
  authDomain: "kprice-kpricemarket.firebaseapp.com",
  databaseURL: "https://kprice-kpricemarket-default-rtdb.firebaseio.com/",
  storageBucket: "kprice-kpricemarket.firebasestorage.app",
};

//enableLogging(true);//gerçekleşen tüm işlemlerin loglarını consoleda görüntülemek için
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export const activeSubscriptions = {};

const adjustData_miniTicker = (data) => {
  if (!data || typeof data !== "object") {
    return data;
  }

  const numberFields = ["c", "h", "l", "o", "q", "v", "last_MT"];

  const adjustedData = Object.entries(data).reduce((acc, [key, value]) => {
    if (value && typeof value === "object") {
      const adjustedValue = { ...value };

      numberFields.forEach((field) => {
        if (adjustedValue[field] !== undefined) {
          adjustedValue[field] = Number(adjustedValue[field]);
        }
      });

      acc[key] = adjustedValue;
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});

  return adjustedData;
};

const adjustData_productForFutureProcesses = (data) => {
  if (!data || typeof data !== "object") {
    return data;
  }

  // Güncellenmesi gereken alanlar
  const numberFields = ["current_price", "last_MT", "last_f"];

  // Yeni bir nesne oluştur ve sadece belirtilen alanları dönüştür
  const adjustedData = { ...data };
  numberFields.forEach((field) => {
    if (adjustedData[field] !== undefined) {
      adjustedData[field] = Number(adjustedData[field]);
    }
  });

  return adjustedData;
};

const adjustData_candleProduct = (data) => {
  if (!data || typeof data !== "object") {
    return data;
  }

  // Güncellenmesi gereken alanlar
  const numberFields = ["current_price", "last_MT", "last_f"];

  // Yeni bir nesne oluştur ve sadece belirtilen alanları dönüştür
  const adjustedData = { ...data };
  numberFields.forEach((field) => {
    if (adjustedData[field] !== undefined) {
      adjustedData[field] = Number(adjustedData[field]);
    }
  });

  if (adjustedData.last_trades_string) {
    try {
      const parsedTrades = JSON.parse(adjustedData.last_trades_string);

      adjustedData.last_trades = Object.entries(parsedTrades).reduce(
        (acc, [key, value]) => {
          acc[key] = isNaN(value) ? value : Number(value); // Sayı olarak dönüştür
          return acc;
        },
        {}
      );

      delete adjustedData.last_trades_string; // Eski alanı kaldır
    } catch (error) {
      //console.error("Error parsing last_trades_string:", error);
    }
  }

  return adjustedData;
};

const firebasePathMapping = {
  CONNECT_MINI_TICKER_UPDATED: {
    key: 'miniTicker',
    adjustData: adjustData_miniTicker,
  },
  CONNECT_PRODUCT_UPDATED_FOR_PROCESSES: {
    key: 'productUpdates',
    adjustData: adjustData_productForFutureProcesses,
  },
  CONNECT_CANDLE_PRODUCT_UPDATED: {
    key: 'productUpdates',
    adjustData: adjustData_candleProduct,
  },
};

export const startFirebaseSubscription = (type, params, callback) => {
  const firebasePath = firebasePathMapping[type] ? firebasePathMapping[type].key : null;
  if (!firebasePath) {
    //console.log(`Firebase yoluna eşlenemeyen type: ${type}`);
    return;
  }

  const fullPath = params ? `${firebasePath}/${params}` : firebasePath;
  const reference = ref(database, fullPath);
  const subscribeKey = params ? `${type}_${params}` : type;

  if (activeSubscriptions[subscribeKey]) {
    return;
  }

  const listener = (snapshot) => {
    const data = snapshot.val();
    //console.log(data);
    const adjustDataFunction = firebasePathMapping[type]?.adjustData;
    const newData = adjustDataFunction ? adjustDataFunction(data) : data;
    callback(null, newData);
  };

  onValue(reference, listener);

  activeSubscriptions[subscribeKey] = reference;
};

export const stopFirebaseSubscription = (type, params) => {
  const subscribeKey = params ? `${type}_${params}` : type;
  const subscription = activeSubscriptions[subscribeKey];
  
  if (subscription) {
    off(subscription);
    delete activeSubscriptions[subscribeKey];
    //console.log(`Abonelik sonlandırıldı: ${subscribeKey}`);
  } else {
    //console.log(`Sonlandırılacak abonelik bulunamadı: ${subscribeKey}`);
  }
};