import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from 'primereact/dialog';
import Loading from "../components/Global/Loading";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { onSupportChatOpen, onSupportChatClose, onGetSupportChatMore } from "../Reducer/kpriceReducer";

const SupportChat = ({ ticket, ticketStateText, ticketStateColor, ticketState, ticketStatus, ticketOperationStatus }) => {

    const dispatch = useDispatch();

    const supportChat = useSelector((state) => state.kprice.supportChat.data);
    const messagesRef = useRef();
    
    const [newMessage, setNewMessage] = useState("");
    const [newMessageDanger, setNewMessageDanger] = useState(false);
    const [newMessageLoading, setNewMessageLoading] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(true);
    const newMessageRef = useRef("");
    const inputRef = useRef(null);
    const [firstLoad, setFirstLoad] = useState(0);
    
    const [newOptionMessage, setNewOptionMessage] = useState("");
    const newOptionMessageRef = useRef('');
    const [newOptionsCount, setNewOptionsCount] = useState("4");

    const [displayCreateOptions, setDisplayCreateOptions] = useState(false);

    const [loadingMore, setLoadingMore] = useState(false);
    const itemsScrollPosition = useRef(0);

    const chatScrollTop = useRef(0);
    const chatScrollHeight = useRef(0);
    const chatClientHeight = useRef(0);

    const [changeState1Loading, setChangeState1Loading] = useState(false);
    const [changeState2Loading, setChangeState2Loading] = useState(false);
    const [changeState3Loading, setChangeState3Loading] = useState(false);
    const [changeState1Disabled, setChangeState1Disabled] = useState(false);
    const [changeState2Disabled, setChangeState2Disabled] = useState(false);
    const [changeState3Disabled, setChangeState3Disabled] = useState(false);
    const [changeState1Valid, setChangeState1Valid] = useState(false);
    const [changeState2Valid, setChangeState2Valid] = useState(false);
    const [changeState3Valid, setChangeState3Valid] = useState(false);
    const [changeState1ErrorMessage, setChangeState1ErrorMessage] = useState(null);
    const [changeState2ErrorMessage, setChangeState2ErrorMessage] = useState(null);
    const [changeState3ErrorMessage, setChangeState3ErrorMessage] = useState(null);

    const patterns = {
        general: /^[^'`"“”‘’«»‹›\<\>;\{\}\$\&\\\%\*\@\!\?\#\^\|\[\]\+=~\/\(\)]*$/,
        no_double_space_or_trim: /^(?! )(?!.*  )(?!.* $).+$/,
        no_other_spaces: /^[^\t\n\r]*$/,
        char_limit_100: /^.{1,100}$/,
        char_limit_1000: /^.{1,1000}$/,
    };

    const validatePattern = (field, fieldLabel) => {
        if (!patterns["general"].test(field)) {
            return `Invalid character in ${fieldLabel}`;
        }
        else if (!patterns["no_double_space_or_trim"].test(field)) {
            return `Invalid spacing in ${fieldLabel}`;
        }
        else if (!patterns["no_other_spaces"].test(field)) {
            return `Unexpected spaces in ${fieldLabel}`;
        }
        else if (!patterns["char_limit_1000"].test(field) && fieldLabel == "Message") {
            return `${fieldLabel} exceeds character limit`;
        }
        else if (!patterns["char_limit_100"].test(field) && fieldLabel == "Subject") {
            return `${fieldLabel} exceeds character limit`;
        }
    };

    useEffect(() => {
        if(ticket && ticket.log_id){
            dispatch(onSupportChatOpen({ logId: ticket.log_id }));
        }
    }, [ticket]);

    useEffect(() => {
        changeStateControl(null);
    }, [ticketState, ticketStatus, ticketOperationStatus]);
    
    const addMessage = async (isSubmit) => {
        setNewMessageDanger(false);
        const newValue = newMessageRef.current;

        if(newValue != ""){
            if(isSubmit && ticket && ticket.log_id && !newMessageLoading){
                let submitControl = true;

                let tempReturnValue = validatePattern(newValue, "Message");
                if(tempReturnValue){
                    submitControl = false;
                    setNewMessageDanger(true);
                    toast.error(tempReturnValue);
                }

                if(submitControl){
                    setNewMessageLoading(true);

                    let data = {
                        "ticket_log_id": ticket.log_id,
                        "message": newValue,
                    }
                    
                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();
        
                    axios.post('https://operationapi.kpriceapp.com/support/update', 
                        JSON.stringify(data),
                        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                    )
                    .then(function (response) {
                        if(response.status == "200"){
                            newMessageRef.current = "";
                            setNewMessage("");
                        }
                        else{//hatalı durum için
                            toast.error(`There was a problem sending the message, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                            setNewMessageLoading(false);
                        }
                    })
                    .catch(function (error) {
                        toast.error(error.response.data.error);
                        setNewMessageLoading(false);
                    });
                }
            }
            else if(validatePattern(newValue, "Message")){
                setNewMessageDanger(true);
            }
        }
    }

    const changeState = async (newState) => {
        setChangeState1Disabled(true);
        setChangeState2Disabled(true);
        setChangeState3Disabled(true);

        if(newState == "resolved"){
            setChangeState1Loading(true);
        }
        else if(newState == "waiting_for_customer"){
            setChangeState2Loading(true);
        }
        else if(newState == "close_for_support"){
            setChangeState3Loading(true);
        }

        let data = {
            "ticket_log_id": ticket.log_id,
            "state": newState,
        }
        
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        axios.post('https://operationapi.kpriceapp.com/support/updatestate', 
            JSON.stringify(data),
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
        )
        .then(function (response) {
            if(response.status == "200"){

            }
            else{//hatalı durum için
                toast.error(`There was a problem changing state!${response.data.message ? ` (${response.data.message})` : ""}`);
            }
        })
        .catch(function (error) {
            toast.error(error.response.data.error);
        })
        .finally(function () {
            setChangeState1Loading(false);
            setChangeState2Loading(false);
            setChangeState3Loading(false);
            setChangeState1Disabled(false);
            setChangeState2Disabled(false);
            setChangeState3Disabled(false);
        });
    }

    const changeStateControl = (newState) => {
        if(ticket && ticketState && ticketStatus && ticketOperationStatus){
            const currentState = ticketState;
            const currentStatus = ticketStatus;
            const currentOperationStatus = ticketOperationStatus;

            let errorMessage1 = null;
            let errorMessage2 = null;
            let errorMessage3 = null;

            //resolved
            if(currentState == "resolved"){
                errorMessage1 = "Ticket is already in resolved state";
            }
            else if(currentState == "closed"){
                errorMessage1 = "Invalid state transition from closed to resolved";
            }
            else if(currentStatus == "deactive"){
                errorMessage1 = "Ticket is already in deactive status";
            }
            else if(currentOperationStatus == "deactive"){
                errorMessage1 = "Operation status already deactive";
            }
            
            //waiting_for_customer
            if(currentState == "waiting_for_customer"){
                errorMessage2 = "Ticket already in waiting_for_customer state";
            }
            else if(currentState == "resolved"){
                errorMessage2 = "Invalid state transition from resolved to waiting_for_customer";
            }
            else if(currentState == "closed"){
                errorMessage2 = "Invalid state transition from closed to waiting_for_customer";
            }
            else if(currentStatus == "deactive"){
                errorMessage2 = "Invalid status transition to waiting_for_customer";
            }
            else if(currentOperationStatus == "deactive"){
                errorMessage2 = "Invalid operation_status transition to waiting_for_customer";
            }
            
            //close_for_support
            if(currentState != "closed"){
                errorMessage3 = "Invalid state for close_for_support operation";
            }
            else if(currentStatus != "deactive"){
                errorMessage3 = "Invalid status for close_for_support operation";
            }
            else if(currentOperationStatus == "deactive"){
                errorMessage3 = "Operation status already deactive";
            }

            setChangeState1Valid(errorMessage1 == null);
            setChangeState2Valid(errorMessage2 == null);
            setChangeState3Valid(errorMessage3 == null);

            setChangeState1ErrorMessage(errorMessage1);
            setChangeState2ErrorMessage(errorMessage2);
            setChangeState3ErrorMessage(errorMessage3);

            if(newState && (newState == "resolved" || newState == "waiting_for_customer" || newState == "close_for_support")){
                let stateValid = false;

                if(newState == "resolved"){
                    if(errorMessage1){
                        toast.error(errorMessage1);
                    }
                    else{
                        stateValid = true;
                    }
                }
                else if(newState == "waiting_for_customer"){
                    if(errorMessage2){
                        toast.error(errorMessage2);
                    }
                    else{
                        stateValid = true;
                    }
                }
                else if(newState == "close_for_support"){
                    if(errorMessage3){
                        toast.error(errorMessage3);
                    }
                    else{
                        stateValid = true;
                    }
                }

                if(stateValid){
                    confirmDialog({
                        message: `Are you sure you want to change the state to ${ticketStateText(newState)}?`,
                        header: 'Change State',
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => changeState(newState),
                        draggable: false,
                    });
                }
            }
        }
    }

    useEffect(() => {
        addMessage(false);
    }, [newMessage]);

    useEffect(() => {
        if(messagesRef && messagesRef.current){
            if(!loadingMore){
                messagesRef.current.scrollTop = messagesRef.current.scrollHeight - itemsScrollPosition.current - 66;
            }
        }
    }, [loadingMore]);

    const controlOptionsCount = (value) => {
        let inputValue = value;

        let numericValue = inputValue.replace(/[^0-9]/g, '');

        if (numericValue.startsWith('0') && numericValue.length > 1) {
            numericValue = numericValue.replace(/^0/, '');
        }

        let boundedValue = numericValue;
        if (numericValue !== '') {
            let number = Number(numericValue);
            boundedValue = number > 10 ? '10' : (number < 0 ? '0' : numericValue);
        }

        return boundedValue;
    }

    const addMessageWithOptions = () => {

        const newValue = newOptionMessageRef.current;

        if(newValue != ""){

            const numberNewOptionsCount = Number(newOptionsCount);
            if(newOptionsCount && newOptionsCount != "" && numberNewOptionsCount > 0){

                let options = [];
                let validOptionsCount = 0;
                let optionForLoop = null;

                for(let i = 0; i < numberNewOptionsCount; i++){
                    optionForLoop = document.getElementById(`option${i}`);
                    if(optionForLoop){
                        if(optionForLoop.value != ""){
                            validOptionsCount += 1;
                            options.push({title: optionForLoop.value});
                        }
                    }
                }

                if(validOptionsCount == numberNewOptionsCount){
                    /*
                    let messagesCopy = [...messages];
                    messagesCopy.push({
                        type: 'sent',
                        message: newValue,
                        options: options,
                    });
        
                    setMessages(messagesCopy);
                    */
                    newOptionMessageRef.current = "";
                    setNewOptionMessage("");
                    
                    setDisplayCreateOptions(false);
                }
                else{
                    toast.error("Please do not leave the options blank!");
                }

            }
            else{
                toast.error("Number of options must be greater than zero!");
            }

        }
        else{
            toast.error("Please do not leave the message field blank!");
        }

    }

    const elementsOnScroll = async (e) => {
        const { scrollTop } = e.target;

        if (scrollTop == 0 && supportChat && supportChat.last_evaluated_key && !loadingMore) {//eğer divin en üstüne gelindiyse
            if(messagesRef && messagesRef.current){
                itemsScrollPosition.current = messagesRef.current.scrollHeight - messagesRef.current.scrollTop;
            }

            setLoadingMore(true);
            dispatch(onGetSupportChatMore());
        }
        
        chatScrollTop.current = e.target.scrollTop;
        chatScrollHeight.current = e.target.scrollHeight;
        chatClientHeight.current = e.target.clientHeight;

        const messageDistance = window.innerWidth > 1600 ? 35 : (window.innerWidth > 500 ? 25 : 15);

        if(chatScrollTop.current + chatClientHeight.current >= chatScrollHeight.current - messageDistance){
            setNewMessageAlert(false);
        }
    }

    const scrollDown = () => {
        if(!loadingMore && messagesRef.current){
            messagesRef.current.scrollTo({
                top: messagesRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }

        setNewMessageAlert(false);
    }

    useEffect(() => {
        if(firstLoad != 2 && messagesRef.current){
            messagesRef.current.scrollTo({
                top: messagesRef.current.scrollHeight,
                //behavior: 'smooth',
            });

            if(firstLoad == 0){setFirstLoad(1);}
            else{setFirstLoad(2);}
        }
        else{
            if(!loadingMore && messagesRef.current){
                const messageDistance = window.innerWidth > 1600 ? 35 : (window.innerWidth > 500 ? 25 : 15);
                if(!(chatScrollTop.current + chatClientHeight.current >= chatScrollHeight.current - messageDistance)){
                    setNewMessageAlert(true);
                }
                else{
                    scrollDown();
                }
            }
        }
        
        setLoadingMore(false);

        if(newMessageLoading){
            setNewMessageLoading(false);
            inputRef.current.focus();
        }
    }, [supportChat]);

    useEffect(() => {
        if(!displayCreateOptions){
            newOptionMessageRef.current = "";
            setNewOptionMessage("");
            setNewOptionsCount("4");
        }
    }, [displayCreateOptions]);

    useEffect(() => {
        return () => {
            dispatch(onSupportChatClose());
        };
    }, []);

    return(
        <div className="inner-chat">
            <ConfirmDialog />
            <Dialog header="Create Options" visible={displayCreateOptions} draggable={false} resizable={false} className="section-withdraw-dialog support-dialog" onHide={() => setDisplayCreateOptions(false)}>
                <div className="section-withdraw-dialog-body">
                    <div className="create-options">
                        <div className="message">
                            <div className="title">Message</div>
                            <input 
                                type="text"
                                onChange={(event) => {newOptionMessageRef.current=event.target.value;setNewOptionMessage(event.target.value);}}
                                value={newOptionMessage}
                                placeholder="Message"
                                className="input"
                            />
                        </div>
                        <div className="options">
                            <div className="title">Number of Options <span>(1-10)</span></div>
                            <input 
                                type="text"
                                onChange={(event) => {setNewOptionsCount(controlOptionsCount(event.target.value));}}
                                value={newOptionsCount}
                                placeholder="Number of Options (1-10)"
                                className="input"
                            />
                            <div className="new-options">
                                {newOptionsCount && newOptionsCount != "" ? 
                                    Array.from({ length: Number(newOptionsCount) }, (_, index) => index).map((item) => (
                                        <input type="text" id={`option${item}`} key={`option${item}`} placeholder={`Option ${item + 1}`} />
                                    ))
                                    : 
                                    <div className="no-option">
                                        Please enter number of options
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="button">
                            <div className="send-button" onClick={() => addMessageWithOptions()}>Send</div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div className={`messages ${supportChat && supportChat.support_request_events && supportChat.support_request_events.length > 0 ? "" : "loading"}`}>
                <div className="title-area">
                    <Tooltip title="Profile" placement="right" className="profile-button">
                        <a href={`/profile?customer_id=${ticket.customer_id}`} target="_blank">
                            <i className="fa-solid fa-user"></i>
                            <span>{ticket.customer_id}</span>
                        </a>
                    </Tooltip>
                    <div className="ticket-info">
                        <Tooltip title={ticket.subject} placement="bottom" className="subject">
                            <div>{ticket.subject}</div>
                        </Tooltip>
                        <div className="ticket-id">Ticket ID: <b>{ticket.ticket_id}</b></div>
                        <div className={`ticket-state ${ticketStateColor(ticketState)}`}>{ticketStateText(ticketState)}</div>
                    </div>
                    <div className="state-buttons">
                        <Tooltip title={changeState1ErrorMessage} placement="bottom" className={`state-button ${ticketStateColor("resolved")} ${(changeState1Disabled || !changeState1Valid) ? "disabled" : ""} ${changeState1Loading ? "loading" : ""}`} onClick={() => {if(changeState1Valid && !changeState1Disabled){changeStateControl("resolved")}}}>
                            <div>{changeState1Loading ? <Loading /> : ticketStateText("resolved")}</div>
                        </Tooltip>
                        <Tooltip title={changeState2ErrorMessage} placement="bottom" className={`state-button ${ticketStateColor("waiting_for_customer")} ${(changeState2Disabled || !changeState2Valid) ? "disabled" : ""} ${changeState2Loading ? "loading" : ""}`} onClick={() => {if(changeState2Valid && !changeState2Disabled){changeStateControl("waiting_for_customer")}}}>
                            <div>{changeState2Loading ? <Loading /> : ticketStateText("waiting_for_customer")}</div>
                        </Tooltip>
                        <Tooltip title={changeState3ErrorMessage} placement="bottom" className={`state-button ${ticketStateColor("close_for_support")} ${(changeState3Disabled || !changeState3Valid) ? "disabled" : ""} ${changeState3Loading ? "loading" : ""}`} onClick={() => {if(changeState3Valid && !changeState3Disabled){changeStateControl("close_for_support")}}}>
                            <div>{changeState3Loading ? <Loading /> : ticketStateText("close_for_support")}</div>
                        </Tooltip>
                    </div>
                </div>
                <div className="inner" ref={messagesRef} onScroll={(e) => elementsOnScroll(e)}>
                    {supportChat && supportChat.support_request_events && supportChat.support_request_events.length > 0 ? 
                        <>
                            {loadingMore && <div className="loading-more"><Loading /></div>}
                            {supportChat.support_request_events.map((item, itemIndex) => (
                                <div className="item" key={item.event_id}>
                                    <div className={item.event_user_type == "Support" ? "right-field" : (item.event_user_type == "Customer" ? "left-field" : null)}>
                                        <div className={`message ${item.options && item.options.length > 0 ? "with-option" : ""}`}>
                                            {item.event_message}
                                        </div>
                                        {item.options && item.options.length > 0 && 
                                            item.options.map((option, optionIndex) => (
                                                <div className="option" key={`${itemIndex}-${optionIndex}`}>{option.title}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))}
                        </>
                        : (<div className="loading"><Loading /></div>)
                    }
                </div>
            </div>
            <div className="input-area">
                {newMessageAlert && <div className="new-message-alert" onClick={() => scrollDown()}><i className="fa-solid fa-chevron-down"></i></div>}
                <div className="input-inner-area">
                    <input 
                        ref={inputRef}
                        type="text"
                        onChange={(event) => {if(!newMessageLoading){newMessageRef.current=event.target.value;setNewMessage(event.target.value);}}}
                        value={newMessage}
                        placeholder="Type a message"
                        className={`${newMessage != "" ? "with-button " : undefined} ${newMessageDanger ? "danger " : undefined}`}
                        onKeyDown={(event) => {if(event.key === "Enter"){addMessage(true);}}}
                    />
                    {newMessage != "" && supportChat && supportChat.support_request_events && supportChat.support_request_events.length > 0 && 
                        <i className="fa-solid fa-circle-right send-button" onClick={() => addMessage(true)}></i>
                    }
                    {newMessageLoading && <div className="new-message-loading"><Loading /></div>}
                </div>
                {/*<div className="create-options-button" onClick={() => {if(supportChat && supportChat.support_request_events && supportChat.support_request_events.length > 0){setDisplayCreateOptions(true)}}}>
                    <i className="bx bxs-plus-circle"></i>
                    <span>Create Options</span>
                </div>*/}
            </div>
        </div>
    )

}

export default SupportChat;